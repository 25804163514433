import { useState, useEffect, useContext } from 'react';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import LoginForm from 'components/Forms/Login';
import { BASENAME_URL_HOME, BASENAME_URL_PROCESSES } from "constants/settings";
import { isDefined } from 'services/util/auxiliaryUtils';
import IntlMessages from 'components/util/IntlMessages';
import FooterContainer from 'pages/Restricted/shared/Footer';


// import { ReactComponent as BackgroundLeft } from 'assets/images/auth/svg/graphic-left.svg';
// import { ReactComponent as BackgroundRight } from 'assets/images/auth/svg/graphic-right.svg';
import { ReactComponent as FieldLogo } from 'assets/@efz/logos/logo-efz-vert.svg';
import { ReactComponent as NGBackground } from 'assets/images/auth/svg/login.svg';

// Services
import { withTracker } from 'services/withTracker';

// Contexts
import AuthContext from 'contexts/authContext';
import UserContext from 'contexts/userContext';
import { isUserFieldV2 } from 'services/user';

const Login = () => {

    const [mounted, setMounted] = useState(false);
    const { loginHandler, isUserAuthenticated, isLoadingLogin } = useContext(AuthContext);
    const { userTypeID, userCompanyID } = useContext(UserContext);

    let navigate = useNavigate();

    // componentWillUnmountWithUseEffect
    useEffect(() => {
        return () => {
            toast.dismiss(); // dismiss all notifications
        }
    }, []); //eslint-disable-line

    // if user is authenticated, redirect
    useEffect(() => {
        if (isUserAuthenticated && isDefined(userTypeID) && isDefined(userCompanyID)) {

            !isUserFieldV2(userTypeID) ? navigate(BASENAME_URL_HOME) : navigate(BASENAME_URL_PROCESSES);
        }
    }, [isUserAuthenticated]); //eslint-disable-line

    //handleOnSubmit
    const handleOnSubmit = ({ username, password }) => {
        !mounted && setMounted(true);
        loginHandler({
            username,
            password,
        })
    };

    // useEffect(() => {
    //     if (!isUserAuthenticated) {
    //       window._paq.push(['trackEvent', 'Current page', 'login']);
    //     }
    // }, [isUserAuthenticated]); // eslint-disable-line

    return (
        <div className={'app-main-login'}>

            <section className="login-svg-bg">
                <div className="login-svg-ng">
                    <NGBackground />
                </div> 
            </section>

            <section className="login-top-section">
                <div>
                    <div className="app-login-container animated fadeIn animation-duration-2">
                        <div className="login-content m-4">
                            {/* Header */}
                            <div className='login-header'>
                                <FieldLogo />
                            </div>
                            {/* Body */}
                            <div className="login-body">
                                <div className="login-body-text">
                                    <h4><IntlMessages id={'page.login.subtitle'} /></h4>
                                </div>
                                <LoginForm
                                    isRequesting={isLoadingLogin}
                                    handleOnSubmit={handleOnSubmit}
                                    mounted={mounted}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <FooterContainer isUserAuthenticated={false} />
        </div>
    );
};

export default (withTracker(Login, 'Login'));
